import React from "react";
import { graphql, useStaticQuery } from "gatsby";

// Components
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import HeroChevron from "../../../components/hero/hero-chevron";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import SEO from "../../../components/seo/seo";
import FaqAccordion from "../../../components/faq/faq-accordion";
import StretchedLinkCards from "../../../components/card/stretched-link-cards";
import MortgageCalculator from "../../../components/calculators/mortgage-calculator/calculator";
import HelpSection from "../../../components/home-loans/help-section";
import ReturningBorrowerLink from "../../../components/home-loans/returning-borrower-link";

// Helpers
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";

// Images from static folder
import CustomLargeCards from "../../../components/card/custom-large-cards";
import NewUIWafdBankBenefits from "../../../components/wafd-bank-benefits/new-ui-wafd-bank-benefits";

import useCardBlogsData from "../../../hooks/use-card-blogs-data";
import getFeaturedCardBlogData from "../../../helpers/getFeaturedCardBlogData";

const HomeLoansMortgageRefinance = () => {
  const pageData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/personal-banking/home-loans/refinancing/hero-ref-12192022-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/personal-banking/home-loans/refinancing/hero-ref-12192022-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/personal-banking/home-loans/refinancing/hero-ref-12192022-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/personal-banking/home-loans/refinancing/hero-ref-12192022-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/personal-banking/home-loans/refinancing/hero-ref-12192022-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/personal-banking/home-loans/refinancing/hero-ref-12192022-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/personal-banking/home-loans/refinancing/hero-ref-12192022-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
      largeCard1: file(relativePath: { eq: "cards/personalization/thumbnail-closing-costs-03152023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
      largeCard2: file(relativePath: { eq: "cards/thumbnail-consider-refinance-06132023-730.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            placeholder: BLURRED
            quality: 100
            width: 600
            transformOptions: { cropFocus: CENTER }
            layout: CONSTRAINED
          )
        }
      }
    }
  `);

  const cardsData = useCardBlogsData([
    "/blog/home-ownership/things-to-consider-when-refinancing-your-home",
    "/blog/home-ownership/when-and-why-you-should-refinance-your-home",
    "/blog/home-ownership/should-i-refinance-at-higher-rate"
  ]);

  const featuredCardsData = getFeaturedCardBlogData(cardsData, { hasText: false, idString: "mb-card-" });

  const heroChevronData = {
    id: "refinance-hero",
    ...getHeroImgVariables(pageData),
    altText: "Mature man in home kitchen sorting the home finances on laptop, talking on cell phone.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Refinancing Made Simple"
          }
        },
        {
          id: 2,
          button: {
            id: "hero-cta-1",
            text: "See Today's Rates",
            url: "/personal-banking/home-loans/mortgage-rates",
            class: "btn-white",
            containerClass: "mb-3"
          }
        },
        {
          id: 3,
          button: {
            id: "hero-cta-2",
            text: "Use Loan Calculator",
            url: "#mortgage-calculator-section",
            class: "btn-light",
            containerClass: "mb-3",
            icon: {
              position: "right",
              lib: "fas",
              name: "arrow-down",
              class: "float-right mt-1 ml-3"
            }
          }
        }
      ]
    }
  };

  // It is okay to leave alt text fields empty for decorative images ("don't add information to the content of a page")
  // https://www.w3.org/WAI/tutorials/images/decorative/
  const refinancingBasicsData = {
    sectionClass: "",
    title: "",
    titleClass: "text-center",
    hasGrowEffect: false,
    hasRiseShadowEffect: true,
    useMobileView: false,
    cards: featuredCardsData.map((card) => ({ ...card, titleClass: "text-green-60" }))
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      url: "/personal-banking/home-loans",
      title: "Home Loans"
    },
    {
      id: 3,
      active: true,
      title: "Refinancing"
    }
  ];
  const title = "Mortgage Refinancing - Rates and Calculator",
    description =
      "Need mortgage refinancing? Refinance your mortgage with a bank you can trust. Check out WaFd Bank's mortgage refinancing calculator and rates today.";
  const SEOData = {
    title: title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: title
      },
      {
        name: "description",
        content: description
      },
      {
        property: "og:title",
        content: title
      },
      {
        property: "og:description",
        content: description
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/home-loans/mortgage-refinance"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/thumb-mortgage-refinance-12192022.jpg"
      }
    ]
  };

  const largeCardsImgData = {
    largeCard1: pageData.largeCard1,
    altTextCard1: "Young couple moving in new house, holding keys.",
    largeCard2: pageData.largeCard2,
    altTextCard2: "Man sitting at home on sofa using laptop and thinking while smiling."
  };

  const largeCardsData = [
    {
      title: "Save Up to $600 on Closing Costs With a Checking Account From WaFd Bank*",
      disclaimer:
        "*To qualify for the maximum $600 discount, before closing documents are prepared you must (1) have or open the WaFd checking account, (2) register for EZ-Pay from the new checking account, and (3) sign up for direct deposit of at least one form of a monthly recurring source of income.",
      mainButton: {
        url: "/personal-banking/home-loans/closing-cost-checking-account-offer",
        title: "Find Out How"
      }
    },
    {
      title: "What to Know Before Refinancing",
      subtitle:
        "Whether you're planning to refinance or just considering it, here are a few things to keep in mind and more information about the process.",
      mainButton: {
        url: "/blog/home-ownership/things-to-consider-when-refinancing-your-home",
        title: "Learn More"
      }
    }
  ];

  const AVG_PRICE = 100000;
  const calculatorData = {
    title: "Refinance Loan",
    loanType: "Refinance",
    loanTerm: "30 Year Fixed",
    homeValue: 350000,
    interestRate: 0,
    estimatedPayment: 0,
    purchasePrice: AVG_PRICE,
    cashOutAmount: 0,
    loanAmount: AVG_PRICE,
    disclaimersSectionClass: "py-0"
  };

  const helpSectionData = {
    sectionClass: "bg-light",
    text: "If you've done all your research but aren't sure whether refinancing makes sense, just stop by. We're nearby, and we'll sit down with you and answer any questions you may have."
  };

  const faqAccordionData = {
    id: "refinance-faq-accordion",
    seeMoreFaqsLink: "/personal-banking/home-loans/mortgage-faq",
    seeMoreFaqsId: "refinance-see-more-faqs-link",
    faqCategoryNames: ["Refinance"],
    sectionClass: "section-padding pb-0"
  };

  return (
    <SecondaryLanding>
      <SEO {...SEOData} />
      <HeroChevron {...heroChevronData} />
      <Breadcrumb data={breadcrumbData} />

      <section className="container pb-0">
        <h1>Refinancing</h1>
        <h3>
          Want to lower your monthly mortgage payment, pay off your mortgage faster or pull cash out for that remodeling
          project? Apply to refinance online, where you can securely upload your documents and receive status updates in
          our secure portal. If you're already a WaFd home loan client, be sure to contact your loan officer to discuss
          options.
        </h3>
        <ReturningBorrowerLink loanType="Refinancing Loan" />
      </section>

      <MortgageCalculator {...calculatorData} />

      <CustomLargeCards noBackground imgData={largeCardsImgData} cardsData={largeCardsData} />

      <NewUIWafdBankBenefits />

      <FaqAccordion {...faqAccordionData} />

      <StretchedLinkCards {...refinancingBasicsData} />

      <HelpSection {...helpSectionData} />
    </SecondaryLanding>
  );
};

export default HomeLoansMortgageRefinance;
